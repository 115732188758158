import { useState } from 'react';
import styled from 'styled-components';
import i18n from '../../utils/i18n';

// import components

interface Props {
  lang: 'fr' | 'en' | 'de' | 'it' | 'es';
};

const SelectLang: React.FC<Props> = props => {

  function handleChange(event) {
    setValue(event.target.value);
    i18n.changeLanguage(event.target.value)
  }

  const {
    lang,
  } = props;

  const [value, setValue] = useState(lang);

  return (
    <Container>
      <select
        onChange={handleChange}
        value={value}
        style={{
          backgroundColor: "white",
          border: 'none'
        }}
      >
        <option value="en">En</option>
        <option value="fr">Fr</option>
        <option value="it">It</option>
        <option value="de">De</option>
        <option value="es">Es</option>
      </select>
    </Container>
  );
}
const Container = styled.div`

`

export default SelectLang;