import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';

import { veryLightLightGrey, redText, greyText } from '../../config/colors';
import { resetPassword } from '../../services/SoundSuitService';
// import Components
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import InputText from '../atoms/InputText';
import ButtonText from '../atoms/ButtonText';
import { Helmet } from "react-helmet";

interface Props {

};

const ForgotPassword: React.FC<Props> = props => {

  async function handleNext() {
    setLoading(true);
    if (validate(email)) {
      setError(false);
      const resultReset = await resetPassword({email});
      if (resultReset === '') {
        navigate(`/new-password`, { state: { email }});
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  return (
    <Container>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <Bloc1>
        <Title align="center">{t('Auth.forgotPasswordTitle')}</Title>
      </Bloc1>
      <Bloc2>
        <InputText
          value={email}
          handleChange={setEmail}
          label={t('Register.email')}
        />
        <WrapperButton>
          <ButtonText
            onHandleClick={handleNext}
            colorType='red'
            width="100%"
            height={50}
            borderRadius="big"
            fontSize={12}
            loading={loading}
          >
            {t('Auth.buttonResetPassword')}
          </ButtonText>
          {error && (
            <WrapperEmail>
              <Text color={redText} size="small" >{t('Auth.noEmailFound')}</Text>
            </WrapperEmail>
          )}
        </WrapperButton>
      </Bloc2>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
`;
const Bloc1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  padding-bottom: 40px;
`;
const Bloc2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 220px;
  padding-top: 10px;
`;
const Row = styled.div`
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Separator = styled.div`
  height: 1px;
  width: 30px;
  background-color: ${veryLightLightGrey};
`;
const WrapperButton = styled.div`
  width: 100%;
  margin-top: 15px;
`;
const WrapperEmail = styled.div`
  width: 100%;
`;

export default ForgotPassword;